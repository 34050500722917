import { Stack } from "@mantine/core";
import { Contact } from "./Contact";
import { Frameworks } from "./Frameworks";
import { Languages } from "./Languages";

export const Sidebar = () => <>
    <Stack>
        <Contact />
        <Languages />
        <Frameworks />
    </Stack>
</>