import { Card, Group, ThemeIcon, Title, UnstyledButton, Text } from "@mantine/core";
import { BsGithub } from "react-icons/bs";
import { GrLinkedin } from "react-icons/gr";

interface MainLinkProps {
    icon: React.ReactNode;
    color: string;
    label: string;
    link: string;
}

function MainLink({ icon, color, label, link }: MainLinkProps) {
    return (
      <UnstyledButton
        sx={(theme) => ({
          display: 'block',
          width: '100%',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
  
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          },
        })}
        onClick={()=> window.open(link, "_blank")}
      >
        <Group>
          <ThemeIcon color={color} variant="light">
            {icon}
          </ThemeIcon>
  
          <Text size="sm">{label}</Text>
        </Group>
      </UnstyledButton>
    );
  };

export const Contact = () => 
<Card shadow="sm" p="lg">
    <Group position="apart" style={{ marginBottom: 5 }}>
        <Title order={3}>Contact</Title>
    </Group>
        {MainLink({icon: <GrLinkedin />, color: 'blue', label: 'LinkedIn', link: 'https://linkedin.com/in/joshuagenders'})}
        {MainLink({icon: <BsGithub />, color: 'blue', label: 'Github', link: 'https://github.com/joshuagenders'})}
</Card>
