import { MantineProvider, Stack, ColorSchemeProvider } from "@mantine/core";
import { useState } from "react";
import { Experience } from "./components/Experience";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { Summary } from "./components/Summary";
import { TwoColumnLayout } from "./components/TwoColumnLayout";

function App() {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <MantineProvider
      theme={{
        spacing: { xs: 15, sm: 20, md: 25, lg: 30, xl: 40 },
        colorScheme: darkMode ? "dark" : "light",
      }}
      withGlobalStyles={true}
    >
      <ColorSchemeProvider
        colorScheme={darkMode ? "dark" : "light"}
        toggleColorScheme={() => setDarkMode(!darkMode)}
      >
        <TwoColumnLayout header={<Header />} sidebar={<Sidebar />}>
          <Stack>
            <Summary />
            <Experience />
          </Stack>
        </TwoColumnLayout>
      </ColorSchemeProvider>
    </MantineProvider>
  );
}

export default App;
