import {
  ActionIcon,
  Group,
  Text,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import { BsFillMoonStarsFill, BsSun } from "react-icons/bs";

export const Header = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  return (
    <Group sx={{ height: "100%" }} px={20} position="apart">
      <div>
        <Title
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
          })}
        >
          Joshua Genders
        </Title>
        <Text
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
          })}
        >
          Developer, Scrum Master, Test Automation Engineer
        </Text>
      </div>
      <ActionIcon
        variant="default"
        onClick={() => toggleColorScheme()}
        size={30}
      >
        {colorScheme === "dark" ? (
          <BsSun size={16} />
        ) : (
          <BsFillMoonStarsFill size={16} />
        )}
      </ActionIcon>
    </Group>
  );
};
