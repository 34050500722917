import { Box, Title, Text } from "@mantine/core";
import { summaryText } from "../data";
export const Summary = () => (
  <Box>
    <Title
      sx={(theme) => ({
        color:
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
      })}
      order={2}
    >
      Summary
    </Title>
    <Text
      sx={(theme) => ({
        color:
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
      })}
    >
      {summaryText}
    </Text>
  </Box>
);
