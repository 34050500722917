import {
  Badge,
  Card,
  Group,
  Image,
  List,
  Title,
  Collapse,
  ChevronIcon,
} from "@mantine/core";
import { useState } from "react";
import {
  CICD,
  cloudTechnologies,
  designFrameworks,
  frontendFrameworks,
  hostingPlatforms,
  monitoringFrameworks,
  testingFrameworks,
  unitTestingFrameworks,
} from "../data";

const FrameworkListing = ({
  title,
  entries,
  expanded = false,
}: {
  title: string;
  entries: string[];
  expanded?: boolean;
}) => {
  const [opened, setOpen] = useState(expanded);
  return (
    <>
      <Badge onClick={() => setOpen((o) => !o)}>
        {title} <ChevronIcon transform={opened ? "rotate(180,0,0)" : ""} />
      </Badge>
      <Collapse in={opened}>
        <List>
          {entries.map((framework) => (
            <List.Item key={framework}>{framework}</List.Item>
          ))}
        </List>
      </Collapse>
    </>
  );
};
export const Frameworks = () => (
  <Card shadow="sm" p="lg">
    <Card.Section>
      <Image
        src="https://cdn.pixabay.com/photo/2018/04/03/23/29/wordpress-3288417_1280.png"
        height={100}
        alt="Programming Languages"
      />
    </Card.Section>

    <Group position="apart" style={{ marginBottom: 5, marginTop: 10 }}>
      <Title order={3}>Frameworks / Tech</Title>
    </Group>
    <FrameworkListing
      title="Frontend"
      entries={frontendFrameworks}
      expanded={true}
    />
    <FrameworkListing title="System Testing" entries={testingFrameworks} />
    <FrameworkListing title="Unit Testing" entries={unitTestingFrameworks} />
    <FrameworkListing title="Design Frameworks" entries={designFrameworks} />
    <FrameworkListing title="Hosting" entries={hostingPlatforms} />
    <FrameworkListing title="Cloud Technologies" entries={cloudTechnologies} />
    <FrameworkListing title="CI/CD" entries={CICD} />
    <FrameworkListing title="Monitoring" entries={monitoringFrameworks} />
  </Card>
);
