import {
  Accordion,
  Avatar,
  Group,
  Title,
  Text,
  Collapse,
  List,
  ChevronIcon,
  Container,
} from "@mantine/core";
import { ReactElement, useState } from "react";
import { BsDot } from "react-icons/bs";
import { experience } from "../data";

type LabelProps = {
  image: string;
  description?: string;
  jobTitle: string;
  company: string;
};

const Label = ({ image, description, jobTitle, company }: LabelProps) => (
  <Group noWrap>
    <Avatar src={image} radius="xl" size="lg" />
    <div>
      <Text>
        {jobTitle} - {company}
      </Text>
      <Text size="sm" color="dimmed" weight={400}>
        {description}
      </Text>
    </div>
  </Group>
);

const Project = ({
  icon,
  title,
  description,
}: {
  icon?: ReactElement;
  title: string;
  description?: string;
}) => {
  const [opened, setOpen] = useState(false);
  const [underline, setUnderline] = useState(false);
  return description ? (
    <List.Item key={title} icon={icon ?? <BsDot />}>
      <Text
        underline={underline}
        onClick={() => setOpen((o) => !o)}
        onMouseEnter={() => setUnderline(true)}
        onMouseLeave={() => setUnderline(false)}
      >
        {title} <ChevronIcon transform={opened ? "rotate(180,0,0)" : ""} />
      </Text>

      <Collapse in={opened}>
        <Container>{description}</Container>
      </Collapse>
    </List.Item>
  ) : (
    <List.Item key={title} icon={icon ?? <BsDot />}>
      <Text>{title}</Text>
    </List.Item>
  );
};

export const Experience = () => (
  <>
    <Title
      sx={(theme) => ({
        color:
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
      })}
      order={2}
    >
      Experience
    </Title>
    <Accordion>
      {experience.map((job) => (
        <Accordion.Item key={job.description} label={<Label {...job} />}>
          <List>{job.content.map(Project)}</List>
        </Accordion.Item>
      ))}
    </Accordion>
  </>
);
