import { AppShell, Aside, Header, MediaQuery } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ReactElement } from "react";

export type TwoColumnLayoutProps = React.PropsWithChildren<{
  header?: ReactElement;
  sidebar?: ReactElement;
}>;

export const TwoColumnLayout = (props: TwoColumnLayoutProps) => {
  const matches = useMediaQuery("(max-width: 500px)");
  return (
    <AppShell
      padding="md"
      aside={
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
            {props.sidebar}
          </Aside>
        </MediaQuery>
      }
      header={
        <Header height={matches ? 180 : 120} p="xs">
          {props.header}
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          height: "100%",
        },
      })}
      footer={
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
            {props.sidebar}
          </Aside>
        </MediaQuery>
      }
    >
      {props.children}
    </AppShell>
  );
};
